import PropTypes from 'prop-types'
import React from 'react'
import devops from '../images/devops01.jpeg'
import about01 from '../images/bg2.jpg'
import soarbanner from '../images/soarbanner.png'
import koda from '../images/meandkoda2bw.png'
import Video from "../components/video"

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={about01} alt="" />
          </span>
          <p>
            I am an engineering lead, game developer, devops engineer, developer advocate, Northeastern University computer science graduate, and husband.
          </p>
          <p>
            I have been working within the game industry space since 2015, and have since launched 3 titles as a developer and 1 as a lead. My passion for creation is what
            drove me into the space to begin with. Playing games as a child and young adult opened me to a world of limitless potential - an avenue through which I could express
            my own creativity and ideas. Writing code became the means by which I was able to scratch that proverbial creative itch. I treat code as the scaffolding on which those 
            talented in other disciplines, be it animation, 2D and 3D art, writing, voice acting, or composing, can bring their work to life. 
          </p>
          <p>
            This blending of different artistic  disciplines, personal experiences, and talent from across generations is what is so appealing to me about game development. It's what,
            to me, makes video games stand above other forms of entertainment.
          </p>
          <p>
            Game development is a challenge like no other, and I am always seeking opportunities to push the boundaries of my own knowledge and experience more of what this industry has to offer.
          </p>
          {close}
        </article>

        <article
          id="work0"
          className={`${this.props.article === 'work0' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">SOAR</h2>
          <span className="image main">
            <img src={soarbanner} alt="" />
          </span>
          <h3>
            Released Summer 2015 on 
            <a
              href="https://play.google.com/store/apps/details?id=com.Dualleaf.Soar">
              <span className="label"> Google Play </span>
              <a className="icon fa-google"> </a>
            </a>
            as a personal project
          </h3>
          <p>
          Soar is a simple, arcade-cabinet-styled, endless-runner-like that I made in Unity in the Summer of 2015.
          The goal was to understand the game development process from beginning to end, and what it looked like going solo: 
          how to work with Unity, how to package a build for a particular platform, and how to deploy that platform to a marketplace.
          I accomplished what I set out to do in around 5 weeks with a very simple game made from pixel art and some free audio I found online. 
          Despite it's tiny scope, I'm proud of this little app, and it's still something I keep on my phone for the occasional playthrough. 
          </p>
          <p>
          Soar was my first foray into the Unity engine. While I have only worked with it intermittently since, I've found it has a very low
          barrier to entry, and is both simple enough to whip together demo projects and deep enough to build some very complicated and interesting 
          things. Though I predominately work with Unreal now, I still think Unity is an excellent game engine. 
          </p>
          {close}
        </article>

        <article
          id="work1"
          className={`${this.props.article === 'work1' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">B.C.E.</h2>
          <Video
            videoSrcURL="https://www.youtube.com/embed/HqTG5UxmS5A"
            videoTitle="Ultimate Rivals: The Rink — Apple Arcade"
         />
          <h3>
            Released September 12, 2017 on
            <a
              href="https://store.steampowered.com/app/516960/BCE/">
              <span className="label"> Steam </span>
              <a className="icon fa-steam"> </a>
            </a>
            by Blue Drop Games
          </h3>
          <p>
          Before Common Era (B.C.E.) is a top-down multiplayer action game set in the Stone Age. 
          Brandish prehistoric weapons and face off against your enemies across a variety of 
          prehistoric environments. Smash, sock, and skewer your way to the top of the food chain!
          </p>
          <h4>
            My Role
          </h4>
          <p>
            I was a founding member of Blue Drop Games, a team of 3 total engineers, all students at Northeastern Univeristy at the time.
            During our final year at NEU, and for some time after graduating, we worked on B.C.E, a multiplayer caveman brawler built for PC.
            I worked on the entirety of the product, from design and implementation to production planing and marketing. 
            My largest areas of focus were the core game loops, progression, and multiplayer-ready code. After 6 months of development we entered
            the Steam Greenlight process and were Greenlit within 12 days. Afterwards we spent the following year, up until launch in September of 2017,
            building the core game mode, Burnout, and preparing the game for release. In the end, we were immensely proud of what we'd created, and of what
            we'd learned. We still find ourselves occasionally re-downloading it and playing a few matches.
          </p>
          <p>
            B.C.E. was my first introduction to the Unreal Engine, and it has been my go-to ever since. 
          </p>
          {close}
        </article>

        <article
          id="work2"
          className={`${this.props.article === 'work2' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Ultimate Rivals: The Rink</h2>
          <Video
            videoSrcURL="https://www.youtube.com/embed/W0RpivMOiZc"
            videoTitle="Ultimate Rivals: The Rink — Apple Arcade"
         />
          <h3>
            Released December 14, 2019 on 
            <span className="label"> Apple Arcade </span>
            <a className="icon fa-apple"> </a>
            by Bit Fry Game Studios
          </h3>
          <p>
            Ultimate Rivals: The Rinks is a crossover arcade sports game set in a digital world 
            of the not so distant future. Play as athletes from 5 major sports leagues, including 
            the NHL, NBA, WNBA, NFLPA, and USWNT, in fast-paced ice hockey matches. Compete with your 
            friends online and unlock the full roster of incredible athletes!
          </p>
          <h4>
            My Role: Gameplay and DevOps Engineer
          </h4>
          <p>
            I joined Bit Fry right when the partnership with Apple began. We were preparing to take the existing Rink
            prototype and make it a fully-fledged mutliplayer mobile experience. Initially there were only 4 
            engineers total on the project, myself included, and I was once again able to involve myself in multiple verticals
            of the product's development. At first, I primarly worked on the gameplay side of things, applying the knowledge
            I had gained from B.C.E. to flesh out the abilities and make them work online. Quickly however I realized a major
            time sink in the development process - getting builds to QA for feature testing. In addition to my gameplay work, I
            took the responsiblilty of automating our builds for each platform (iOS, tvOS, MacOS, Linux, and Windows) and ensuring 
            automatically delivery of build to our QA team. The time it took for QA to recieve and vet a build dropped massively, 
            and because of this, we were able to iterate much faster.
          </p>
          <p>
            It was a challenge to maintain 5 launch platforms, each with their own deployment specifics and publishing requirements - but 
            our small team was able to pull through and get a solid product out the door on December 14, 2019. Afterwards we shifted gears
             to support two tracks of work: live Rink support, and our next game, Ultimate Rivals: The Court. 
          </p>
          {close}
        </article>

        <article
          id="work3"
          className={`${this.props.article === 'work3' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Ultimate Rivals: The Court</h2>
          <Video
            videoSrcURL="https://www.youtube.com/embed/O-jbUXsS350"
            videoTitle="Ultimate Rivals: The Court - Gameplay Launch Trailer"
         />
          <h3>
            Released July 9, 2021 on 
            <span className="label"> Apple Arcade </span>
            <a className="icon fa-apple"> </a>
            by Bit Fry Game Studios
          </h3>
          <p>
            Ultimate Rivals: The Rinks is a crossover arcade sports game set in a digital world 
            of the not so distant future. Play as athletes from 5 major sports leagues, including 
            the NHL, NBA, WNBA, NFLPA, and USWNT, in bombastic basketball matches. Compete with players online
            or you with your friends in head to head matches, complete challenges, and unlock the full roster 
            of incredible athletes!
          </p>
          <h4>
            My Role: Gameplay Engineer, Devops Engineer, and Engineering Lead
          </h4>
          <p>
            With The Rink under our belt, Bit Fry wanted The Court to be even better. We added more athletes, 
            added functionality to allow players to purchase athletes with tokens they earned in game, added 
            challenges to grant tokens, a new game mode called Gauntlet for unlocking legendary athletes like Wayne 
            Gretzky, and a Training mode where players can practice in a more relaxed environment. We developed all 
            this while still maintaing The Rink, and while still supporting the core Ultimate Rivals features, like 
            online multiplayer, head to head matches, and cross-game saved athletes.
          </p>
          <p>
            With this increase in scope came an increase in staff, and my elevation to a leadership role. We built 
            the engineering team up to a total of 13, and our previous, largely flat, team structure had to fall away.
            I managed the team's sprintly workload, ran estimations and scoping sessions, liased with the production team
            and helped keep the product on track without creeping scope, and did so while maintaining the core code framework
            that the game relies on, as well as the automation pipelines. I can proudly say that, from the time between my 
            becoming a lead to the game shipping, the engineering team was never forced into a position of crunch. As a 
            team we were communicative, practical, and efficient, and knew the limitations of time and scope well, always 
            estimating appropriately and setting realistic expectations for the producers. 
          </p>
          <p>
            I am proud of The Court as a product, but I am even more proud of the engineers I was fortunate enough to manage.
            They were, and are, excellent, talented individuals. The camaraderie we fostered was second to none that I've 
            experienced, and I would, in a heartbeat, work with any of them again. 
          </p>
          {close}
        </article>
        <article
          id="devops1"
          className={`${this.props.article === 'devops1' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">DevOps and Automation</h2>
          <span className="image main">
            <img src={devops} alt="" />
          </span>
          <h4>
            Experience
          </h4>
          <p>
            In between my time at Blue Drop Games and Bit Fry, I worked in a few software companies primarily doing web development,
            devops, and build automation. Automation specifically I developed a large interest in, as, the less mundane, routine tasks
            that my team or I have to do, the more time we can spend actually working on the product. 
          </p>
          <p>
            When I started at Bit Fry and began working on The Rink, I was able to translate my experience automating web application 
            builds and deployments into automating builds for iOS, tvOS, macOS, Windows, and Linux for Unreal Engine games. The learning 
            curve was steep - at the time there was not much documentation to build off of. But the resulting automatic build system,
            including all pre-build injection, Jenkins configuration, pipeline files, hardware/virtualware, artifact storage, deployments,
            scheduling, and engineering process, is something I built from the ground up and am immensely proud of. 
          </p>
          <h4>
            Advocacy
          </h4>
          <p>
            Build automation, and by extension, automated testing, is something I am a major advocate for, especially as it relates to the game space.
            The arugment is often "We don't have time to stop and write tests for our code." I find this argument expects too optimistic a future,
            where game features across multiple platform targets, across multiple devices, across multiple modes including online play, are expected
            to work perfectly without regression.
          </p>
          <p>
            The reality that I have experienced is that even the most diligent developer will still be responsible for regressions. Games are massive,
            interconnected webs of disciplines and content, and the complexity derived from that will inevitably lead to unforseen circumstances, bugs,
            and regressions. And simply throwing code over the wall to QA and treating it as their problem fosters a "not my problem" mentality that only 
            breeds resentment and dismissal of responsiblity. The best thing we can do as engineers, as designers, as producers, as anyone involved in this space,
            is to push for the introduction of automated tests, and to treat maintenance of such tests are part of the core development timeline. Let QA focus
            on ensuring features meet design specification, on discovering new bugs, and on being the vocal finger-on-the-pulse that the game is moving in the
            right direction. As developers, we must own the stability of the features we create. 
          </p>
          {close}
        </article>
        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <h4>
            chriskuffert@gmail.com
          </h4>
          {/* <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form> */}
          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/christopher-kuffert-011022a1"
                className="icon fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a href="https://github.com/kuffert" 
              className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/ckuffy/" className="icon fa-instagram">
                <span className="label">Kodas Instagram</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/koda_the_cloud/" className="icon fa-paw">
                <span className="label">Kodas Instagram</span>
              </a>
            </li>
          </ul>
          <span className="image main" >
            <img src={koda} alt="" />
          </span>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
